import create from 'zustand'
import {persist} from "zustand/middleware";

const useAuthStore = create(
    persist(
        (set, _get) => ({
            auth: {
                authData: "",
                isAuthenticated: false,
            },
            logOut: () => set({
                auth: {
                    authData: "",
                    isAuthenticated: false,
                },
            }),
            setAuth: (authData) => set({
                auth: {
                    authData: authData,
                    isAuthenticated: true
                }
            }),
        }),
        {
            name: 'stateStore', // unique name
            getStorage: () => localStorage,
        }
    )
);
export default useAuthStore;