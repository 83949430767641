import create from 'zustand'
import {persist} from "zustand/middleware";

const useAssociationStore = create(
    persist(
        (set, _get) => ({
            aReport: {
                from: "",
                to: "",
            },
            setAReport: (from, to) => set({
                aReport: {
                    from: from,
                    to: to
                }
            }),
        }),
        {
            name: 'useAssociationStore', // unique name
            getStorage: () => localStorage,
        }
    )
);
export default useAssociationStore;