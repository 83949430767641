import {Button, Grid} from "@material-ui/core";
import {CircularProgress} from "@mui/material";
import React from "react";

const DownloadButton = (props) => {
    const {
        submitDownload,
        loading
    } = props;

    return (
        <Grid item xs={12}>
            <Button
                size='large'
                fullWidth
                variant='contained'
                color='primary'
                onClick={submitDownload}
                disabled={loading}
            >
                {loading ? <CircularProgress sx={{color: "white"}} size={30}/> : "download"}
            </Button>
        </Grid>
    )
}

export default DownloadButton
