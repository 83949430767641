import * as React from 'react';
import {NetworkApi} from "../api/NetworkApi";
import { useState} from "react";
import {Grid,  TextField} from "@material-ui/core";
import {DateTimePicker} from "@mui/x-date-pickers";
import DownloadButton from "./DownloadButton";
import dayjs from "dayjs";

export default function AlertsTable({setError}) {

    const [loading, setLoading] = React.useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [from, setFrom] = React.useState(dayjs().subtract(7, 'day'));
    const [to, setTo] = React.useState(dayjs());
    const handleChangeFrom = (newValue) => {
        setFrom(newValue);
    };
    const handleChangeTo = (newValue) => {
        setTo(newValue);
    };

    function submitDownload() {
        setLoading(true);
        NetworkApi.getAlerts(from.format(), to.format())
            .then(() => setLoading(false))
            .catch(err => {
                setLoading(false);
                if (!err?.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg(err.response.data['message']);
                }
            });
    }

    return (
        <Grid container spacing={2} style={{paddingTop: "10px"}}>
            <Grid item xs={12}>
                <DateTimePicker
                    label="From"
                    value={from}
                    onChange={handleChangeFrom}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    disabled={loading}
                />
            </Grid>
            <Grid item xs={12}>
                <DateTimePicker
                    label="To"
                    value={to}
                    onChange={handleChangeTo}
                    renderInput={(params) => <TextField fullWidth{...params} />}
                    disabled={loading}
                />
            </Grid>
            <DownloadButton
                submitDownload={submitDownload}
                loading={loading}
            />
        </Grid>
    );
}
