import create from 'zustand'
import {persist} from "zustand/middleware";

const useFeedingReportStore = create(
    persist(
        (set, _get) => ({
            report: {
                from: "",
                to: "",
                unitAlias: "all"
            },
            setReport: (from, to, unitAlias) => set({
                report: {
                    from: from,
                    to: to,
                    unitAlias: unitAlias
                }
            }),
        }),
        {
            name: 'useFeedingReportStore', // unique name
            getStorage: () => localStorage,
        }
    )
);
export default useFeedingReportStore;