import {Button, Grid, TextField} from "@material-ui/core";
import {DataGrid} from "@mui/x-data-grid";
import React from "react";
import {NetworkApi} from "../api/NetworkApi";
import {CircularProgress} from "@mui/material";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);


export default function FeedingUnitData() {
    const [unitAlias, setUnitAlias] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [photoelectric, setPhotoelectric] = React.useState([]);
    const [scale, setScale] = React.useState([]);

    function onUnitChange(e) {
        setUnitAlias(e.target.value);
    }

    const handleSearch = async () => {
        if (unitAlias!=='') {
            setLoading(true);
            setPhotoelectric([]);
            setScale([]);
            NetworkApi.getLatest(unitAlias).then(data => {
                setPhotoelectric([...data.photoelectricSensorDtoList]);
                setScale([...data.scaleSensorDtolist]);
                setLoading(false);
            }).catch(e=>{ setLoading(false);console.error(e)})}
    }
    const photoColumns = [
        {
            field: 'timestamp',
            headerName: 'Time',
            width: 140,
            sortable: false,
            valueFormatter: (params) =>  dayjs(params.value).format('DD.MM HH:mm:ss')
        },
        {field: 'triggered', headerName: 'triggered', width: 140, sortable: false},
    ];
    const scaleColumns = [
        {
            field: 'timestamp',
            headerName: 'Time',
            width: 140,
            sortable: false,
            valueFormatter: (params) =>  dayjs(params.value).add(2,'hour').format('DD.MM HH:mm:ss')
        },
        {field: 'weight', headerName: 'Weight', width: 140, sortable: false,valueFormatter: (params) => Number.parseFloat(params.value).toFixed(4) },
    ];
    return (<Grid container spacing={2} style={{paddingTop: "10px"}}>
        <Grid item xs={10} style={{display: "flex", justifyContent: "end"}}>
            <TextField id="outlined-basic" label="Feeding unit id" variant="outlined" size={"small"}
                       onChange={onUnitChange} disabled={loading}/>
        </Grid>
        <Grid item xs={2}>
            <Button
                size='large'
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSearch}
                disabled={loading}
            >
                {!loading ? "SEARCH" : <CircularProgress sx={{color: "white"}} size={30}/>}
            </Button>
        </Grid>
        <Grid item xs={6} style={{textAlign: "center"}}>Photo electric</Grid>
        <Grid item xs={6} style={{textAlign: "center"}}>Scale</Grid>
        <Grid item xs={6} style={{height: "417px"}}>
            <DataGrid rows={photoelectric} columns={photoColumns}
                      sx={{minWidth: 100}}
                      density="compact"
                      hideFooter
                      getRowId={(row) => row.uuid}/>
        </Grid>
        <Grid item xs={6} style={{height: "417px"}}>
            <DataGrid rows={scale} columns={scaleColumns}
                      density="compact"
                      hideFooter
                      getRowId={(row) => row.uuid}/>
        </Grid>
    </Grid>)

}