import {Card, CardContent, Grid, TextField} from "@material-ui/core";
import {
    Alert, CardHeader,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select, Tab, Tabs
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import {NetworkApi} from "../api/NetworkApi";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import useFeedingReportStore from "../store/useFeedingReportStore";
import {Axios} from "../api/axios";
import useAuthStore from "../store/useAuthStore";
import useAssociationStore from "../store/useAssociationStore";
import {Logout} from "@mui/icons-material";
import DownloadButton from "./DownloadButton";
import AlertsTable from "./AlertsTable";
import FeedingUnitData from "./FeedingUnitData";

export default function FeedingReport() {
    const [tab, setTab] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    const {report, setReport} = useFeedingReportStore();
    const {aReport, setAReport} = useAssociationStore();

    const [from, setFrom] = React.useState(dayjs(report.from));
    const [to, setTo] = React.useState(dayjs(report.to));


    const [aFrom, setAFrom] = React.useState(dayjs(aReport.from));
    const [aTo, setATo] = React.useState(dayjs(aReport.to));

    const [units, setUnits] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [errMsg, setErrMsg] = useState('');
    const {auth, logOut} = useAuthStore();
    const handleChangeFrom = (newValue) => {
        setFrom(newValue);
    };
    const handleChangeTo = (newValue) => {
        setTo(newValue);
    };

    const handleChangeAFrom = (newValue) => {
        setAFrom(newValue);
    };
    const handleChangeATo = (newValue) => {
        setATo(newValue);
    };

    const [unitAlias, setUnitAlias] = React.useState(report.unitAlias);

    const handleChange = (event) => {
        setUnitAlias(event.target.value);
    };
    useEffect(() => {
        Axios.defaults.headers.common['Authorization'] = auth.authData;
        NetworkApi.getFeedingUnits().then(data => {
            let filtered = data.content[0].feedingUnits.map(u => u.alias);
            setUnits(["all", ...filtered]);
        })
    }, [auth]);

    function TabPanel(props) {
        const {children, value, index, ...other} = props;
        return (
            <Grid item container direction='row' alignItems='center' spacing={2}
                  role="tabpanel"
                  hidden={value !== index}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  {...other}
            >
                {value === index && (<>{children}</>)}
            </Grid>
        );
    }


    function submitDownload() {
        setErrMsg("");
        setLoading(true);
        if (tab === 1) {
            setAReport(aFrom, aTo);
            NetworkApi.downloadAssociationReport(aFrom.format(), aTo.format())
                .then(() => setLoading(false))
                .catch(err => {
                    setLoading(false);
                    if (!err?.response) {
                        setErrMsg('No Server Response');
                    } else if (err.response?.status === 400) {
                        setErrMsg(err.response.data['message']);
                    }
                });
        } else {
            setReport(from, to, unitAlias);
            NetworkApi.downloadFeedingReport(from.format(), to.format(),
                unitAlias === "all" ? "" : unitAlias)
                .then(() => setLoading(false))
                .catch(err => {
                    setLoading(false);
                    if (!err?.response) {
                        setErrMsg('No Server Response');
                    } else if (err.response?.status === 400) {
                        setErrMsg(err.response.data['message']);
                    }
                });
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (<Card>
        <CardHeader
            title="Rumenera-base-ui"
            action={
                <IconButton aria-label="logout" color="secondary" onClick={logOut}>
                    <Logout/>
                </IconButton>
            }/>
        <CardContent>
            <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={12}>
                    <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
                        <Tab label="Feeding report" {...a11yProps(0)} />
                        <Tab label="Association report" {...a11yProps(1)} />
                        <Tab label="Alerts Table" {...a11yProps(2)} />
                        <Tab label ="Feeding unit data" {...a11yProps(3)} />
                    </Tabs>
                </Grid>
                <TabPanel value={tab} index={0}>
                    <Grid item xs={12}>
                        <DateTimePicker
                            label="From"
                            value={from}
                            onChange={handleChangeFrom}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimePicker
                            label="To"
                            value={to}
                            onChange={handleChangeTo}
                            renderInput={(params) => <TextField fullWidth{...params} />}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Feeding unit id</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={unitAlias}
                                label="Feeding unit id"
                                onChange={handleChange}
                                disabled={loading}
                            >
                                {units.map((unit, i) => (
                                    <MenuItem key={i} value={unit}>{unit}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <DownloadButton
                        submitDownload={submitDownload}
                        loading={loading}
                    />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Grid item xs={12}>
                        <DateTimePicker
                            label="From"
                            value={aFrom}
                            onChange={handleChangeAFrom}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimePicker
                            label="To"
                            value={aTo}
                            onChange={handleChangeATo}
                            renderInput={(params) => <TextField fullWidth{...params} />}
                            disabled={loading}
                        />
                    </Grid>
                    <DownloadButton
                        submitDownload={submitDownload}
                        loading={loading}
                    />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <AlertsTable
                        setError={setErrMsg}
                    />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                    <FeedingUnitData />
                </TabPanel>

                {errMsg !== '' ? <Grid item xs={12}>
                    <Alert severity="error">{errMsg}</Alert>
                </Grid> : null}
            </Grid>
        </CardContent>
    </Card>);
}
