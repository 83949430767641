import {Container, Grid} from "@material-ui/core";
import React from "react";
import useAuthStore from "../store/useAuthStore";
import FeedingReport from "./FeedingReport";

export default function ReportComponent() {

    return (
        <Container fixed>
            <FeedingReport/>
        </Container>
    );
}
