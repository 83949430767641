import {Navigate, Outlet, useLocation} from "react-router-dom";

import useAuthStore from "../store/useAuthStore";

const PrivateRoute = () => {
    const {auth} = useAuthStore();
    const location = useLocation();
    return (
        auth?.isAuthenticated ? <Outlet/> : <Navigate to="/login" state={{from: location}} replace/>
    );
}
export default PrivateRoute;