import React from 'react';
import {Box} from '@material-ui/core';
import {Route, Routes,} from 'react-router-dom';

import LoginComponent from './components/LoginComponent';
import ReportComponent from './components/ReportComponent';
import PrivateRoute from "./components/PrivateRoute";
import Missing from "./components/Missing";


function App() {
    return (

        <Box
            style={{
                position: 'absolute',
                left: '50%',
                top: '40%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <Routes>
                <Route path='/login' element={< LoginComponent/>}/>
                <Route element={<PrivateRoute/>}>
                    <Route path="/" element={<ReportComponent/>}/>
                </Route>
                <Route path="*" element={<Missing />} />
            </Routes>
        </Box>

    );
}

export default App;
