import {Axios} from './axios';
import {saveAs} from "file-saver";

const basePath = '/hub/api/v1'

export class NetworkApi {

    static login = async (authValue) => {
        return Axios.get(basePath, {
            headers: {'Authorization': authValue}
        });
    };

    static getFeedingUnits = async () => {
        const resp = await Axios.get(basePath + "/feeding_unit/");
        return resp.data;
    };

    static getAlerts = async (from, to) => {
        let csvData = await Axios.get( "/alerts/api/v1/alert/download", {
            params: {
                from: from,
                to: to
            }
        });
        saveAs(new Blob([csvData.data], {
            type: "text/csv;charset=utf-8;",
        }), csvData.headers['content-disposition'].split('filename=')[1].split(';')[0]);
    };
    static getLatest = async (moduleId) => {
        const resp = await Axios.get(`/sensors/api/v1/status/feeding_unit?moduleId=${moduleId}`);
        return resp.data;
    };
    static downloadFeedingReport = async (from, to, feedingUnitAlias) => {

        let csvData = await Axios.get(basePath + "/report/feeding", {
            params: {
                from: from,
                to: to,
                feedingUnitAlias: feedingUnitAlias
            }
        });
        saveAs(new Blob([csvData.data], {
            type: "text/csv;charset=utf-8;",
        }), csvData.headers['content-disposition'].split('filename=')[1].split(';')[0]);
    };
    static downloadAssociationReport = async (from, to,) => {

        let csvData = await Axios.get(basePath + "/report/association", {
            params: {
                from: from,
                to: to
            }
        });
        saveAs(new Blob([csvData.data], {
            type: "text/csv;charset=utf-8;",
        }), csvData.headers['content-disposition'].split('filename=')[1].split(';')[0]);
    };
}
