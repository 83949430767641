import {Button, Card, CardContent, Container, Grid, TextField, Typography} from "@material-ui/core";
import React, {useState} from 'react';
import {Navigate, useLocation, useNavigate} from "react-router-dom";

import {NetworkApi} from "../api/NetworkApi";
import {Axios} from "../api/axios";
import {Alert, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import useAuthStore from "../store/useAuthStore";
import FeedingUnitData from "./FeedingUnitData";

export default function LoginComponent() {
    const [login, setLogin] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errMsg, setErrMsg] = useState('');
    const {auth, setAuth} = useAuthStore();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    function onLoginChange(e) {
        setLogin(e.target.value);
    }

    function onPasswordChange(e) {
        setPassword(e.target.value);
    }

    function handleError(err) {
        return err !== '' ? <Grid item xs={12}>
            <Alert severity="error">{err}</Alert>
        </Grid> : null
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const authValue = 'Basic ' + window.btoa(login + ':' + password);
        try {
            await NetworkApi.login(authValue)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else if (err.response?.status === 404) {
                Axios.defaults.headers.common['Authorization'] = authValue;
                setAuth(authValue);
                navigate(from, {replace: true});
            } else {
                setErrMsg('Login Failed');
            }
        }
    }
    return (
        auth?.isAuthenticated ? <Navigate to="/" state={{from: location}} replace/> :
            <Container maxWidth="xs" fixed>
                <Card>
                    <Grid container>
                        <Container maxWidth="xs" fixed>
                            <Typography variant='h4' align="center">Rumenera-base-ui</Typography>
                        </Container>
                    </Grid>
                    <CardContent>
                        <Grid container direction='row' alignItems='center' spacing={2}>
                            <Grid item xs={3}>
                                Login:
                            </Grid>
                            <Grid item xs={9}>
                                <TextField name="login" variant="outlined" size="small" fullWidth
                                           value={login}
                                           onChange={onLoginChange}/>
                            </Grid>
                            <Grid item xs={3}>
                                Password:
                            </Grid>
                            <Grid item xs={9}>
                                <TextField name="password" variant="outlined" size="small" fullWidth
                                           type="password"
                                           value={password}
                                           onChange={onPasswordChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    size='large'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={handleSubmit}
                                >
                                    LOGIN
                                </Button>
                            </Grid>
                            {handleError(errMsg)}
                        </Grid>
                    </CardContent>
                </Card>
            </Container>

    );
}